import React from "react";
import "../style/ProductCard.css";

const ProductCard = ({ name, img, price, link }) => {
  return (
    <div className="product-container">
      <img src={img} width="100%" alt={name} height="75%" />
      <p className="card-heading">{name}</p>
      <div className="price-container">
        <p className="price">Rs. {price}</p>
      </div>
    </div>
  );
};

export default ProductCard;
