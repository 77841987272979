import React from "react";
import "../style/About.css";
import ProductCard from "./ProductCard";
import { productDetails } from "../data/productData";

const About = () => {
  return (
    <div className="about-container" id="explore">
      <p className="about-heading">Explore</p>
      <div className="product-wrapper">
        {productDetails.map((product) => (
          <ProductCard
            name={product.name}
            price={product.price}
            link={product.link}
            img={product.img}
          />
        ))}
      </div>
    </div>
  );
};

export default About;
