import React from "react";
import rammyaa_logo from "../images/rammyaa_logo.svg";
import "../style/Footer.css";
import { FaInstagram, FaFacebook } from "react-icons/fa";
import { FiMail } from "react-icons/fi";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="logo-container">
        <img
          src={rammyaa_logo}
          alt="rammyaa_logo"
          className="footer-rammyaa-logo"
        />
      </div>
      <div className="footer-content-container">
        <div className="footer-column-1">
          <p>email: rammyaa05@gmail.com</p>
          <p>phone: +91 9717140480</p>
        </div>
        <div className="footer-column-2">
          <a href="mailto:rammyaa05@gmail.com" className="footer-links">
            Become a dealer or wholesaler
          </a>
          <br />
          <br />
          <a href="mailto:rammyaa05@gmail.com" className="footer-links">
            Order now (free delivery)
          </a>
        </div>
        <div className="footer-column-3">
          <a href="https://www.instagram.com/rammyaa_official?igsh=OG02dnUydmp6bzdz">
            <FaInstagram
              size="1.5em"
              className="footer-links"
              style={{ paddingRight: "1em" }}
            />
          </a>
          <a href="https://www.facebook.com/share/GbKDVe5r4tj7WaqE/?mibextid=qi2Omg">
            <FaFacebook
              size="1.5em"
              className="footer-links"
              style={{ paddingRight: "1em" }}
            />
          </a>
          <a href="mailto:rammyaa05@gmail.com">
            <FiMail className="footer-links" size="1.5em" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
