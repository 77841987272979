export const productDetails = [
  {
    id: 1,
    name: "Rammyaa Berbaris Calendula All Purpose Natural Cream",
    price: 185,
    img: "https://ik.imagekit.io/rammyaa05/products/all%20purpose%20with%20bkg%20website.png?updatedAt=1726942093311",
  },
  {
    id: 2,
    name: "Rammyaa Berbaris Calendula Natural Radiance Cream",
    price: 194,
    img: "https://ik.imagekit.io/rammyaa05/products/radiance%20with%20bkg%20website.png?updatedAt=1726942147955",
  },
  {
    id: 3,
    name: "Rammyaa Aloevera Rose Neem and Tulsi Facewash",
    price: 140,
    img: "https://ik.imagekit.io/rammyaa05/products/facewash%20with%20bkg%20website.png?updatedAt=1726942335566",
  },
  {
    id: 4,
    name: "Rammyaa Arnica Anti-hairfall, Anti-dandruff Shampoo",
    price: 122,
    img: "https://ik.imagekit.io/rammyaa05/products/shampoo%20with%20bkg%20website.png?updatedAt=1726942134224",
  },
  {
    id: 5,
    name: "Rammyaa Rosemary Biotin Natural Hairtonic",
    price: 257,
    // img: "https://ik.imagekit.io/rammyaa05/products/hairtonic%20rosemary.jpg?updatedAt=1726915838148",
    img: "https://ik.imagekit.io/rammyaa05/products/hair%20tonic%20with%20bkg%20website.png?updatedAt=1726942100548",
  },
  {
    id: 6,
    name: "Rammyaa Natural Onion Hairoil",
    price: 176,
    img: "https://ik.imagekit.io/rammyaa05/products/onion%20oil%20with%20bkg%20website.png?updatedAt=1726942078975",
  },
];
